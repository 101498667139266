import { lazy } from "react";

import { ProtectedRoutes } from "./ProtectedRoutes";

const MainLayout = lazy(() => import("../layouts/MainLayout"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Utilisateurs = lazy(() => import("../pages/utilisateurs"));
const UserInfo = lazy(() => import("../pages/utilisateurs/UserInfo"));

const Commandes = lazy(() => import("../pages/commandes"));
const UpdateCommande = lazy(() => import("../pages/commandes/Update"));

const Blogs = lazy(() => import("../pages/blogs"));
const UpdatePost = lazy(() => import("../pages/blogs/Update"));
const CreatePost = lazy(() => import("../pages/blogs/Create"));

const NotFound = lazy(() => import("../pages/NotFound"));

const MembersRoutes = {
  path: "/admin",
  element: <ProtectedRoutes />,
  children: [
    {
      element: <MainLayout />,
      children: [
        {
          path: "/admin/board",
          element: <Dashboard />,
        },

        {
          path: "/admin/utilisateurs",
          element: <Utilisateurs />,
        },
        {
          path: "/admin/utilisateurs/:id",
          element: <UserInfo />,
        },

        {
          path: "/admin/commandes",
          element: <Commandes />,
        },
        {
          path: "/admin/commandes/:ref",
          element: <UpdateCommande />,
        },

        {
          path: "/admin/blogs",
          element: <Blogs />,
        },
        {
          path: "/admin/blogs/modifier/:slug",
          element: <UpdatePost />,
        },
        {
          path: "/admin/blogs/create",
          element: <CreatePost />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ],
};

export default MembersRoutes;

import React, { Component } from "react";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log l'erreur dans la console ou effectue d'autres actions nécessaires.
    console.error(error, errorInfo);
  }

  handleReset = () => {
    this.setState({ hasError: false });
  };

  render() {
    // Vérifie si l'application est en mode production.
    const isProduction = process.env.NODE_ENV === "production";

    if (this.state.hasError && isProduction) {
      return (
        <div className="h-full flex flex-col gap-[20px] items-center justify-center">
          <h1 className="text-6xl">Erreur technique</h1>
          <p>Quelque chose a dérapé...</p>
          <p>Veuillez contacter votre mécano (le développeur)</p>
          <Link
            to="/admin"
            type="button"
            onClick={this.handleReset}
            className="bg-primary h-fit py-[15px] px-[20px] rounded-[20px] no-underline font-bold hover:no-underline"
          >
            Faire demi-tour
          </Link>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { Suspense } from "react";
import "./styles/index.scss";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter basename="/">
      <Suspense
        fallback={
          <div className="w-full h-full flex justify-center items-center">
            <div className="loader"></div>
          </div>
        }
      >
        <Routes />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

import { lazy } from "react";

const AuthLayout = lazy(() => import("../layouts/AuthLayout"));
const Connexion = lazy(() => import("../pages/authentification/Connexion"));

const NotFound = lazy(() => import("../pages/NotFound"));

const AuthenticationRoutes = {
  path: "/auth",
  element: <AuthLayout />,
  children: [
    {
      path: "connexion",
      element: <Connexion />,
    },
    {
      path: "inscription",
      element: <p>inscription</p>,
    },
    {
      path: "mot-de-passe-oublie",
      element: <p>mot de passe oublié</p>,
    },
    {
      path: "reinitialiser-mot-de-passe",
      element: <p>réinitialiser le mot de passe</p>,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default AuthenticationRoutes;
